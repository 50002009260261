.more-info {
    font-size: 12px;
    padding-bottom: 4px;
    cursor: pointer;
}
.modal-content {
    height: 400px;
    overflow: auto;
}
.more-title {
    text-align: center;
    margin-top: 30px;
    font-weight: 600;
    color: #1a4487;
}
.modal-title {
    color: #1a4487;
    font-size: 35px;
}
