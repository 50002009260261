.navbar {
    padding: 0;
}
.logo {
    width: 247px;
    height: 78px;
    padding: 14px;
}
.nav-link {
    font-size: 18px;
    font-weight: 600;
    color: #163664;
}
.nav-link.active {
    color: #5da244 !important;
}

.dropdown-divider {
    margin: 0;
}
.login-btn {
    margin-top: 4px;
    padding: 8px 20px;
    display: flex;
    border: 1px solid #163664;
    border-radius: 5px;
    font-weight: 600;
    transition: 0.2s;
    gap: 5px;
    align-items: center;
}

.nav-link {
    font-size: 22px;
}
.dropdown {
    display: flex;
    align-items: center;
}
.dropdown-menu {
    background-color: #e6ebf3;
}
.dropdown-item {
    font-size: 18px;
    padding: 10px 10px;
    &:hover {
        background-color: #5da244;
        color: #fff;
    }
}
.login-btn:hover {
    opacity: 0.85;
    background-color: #6a9e57;
    color: #fff;
}

.user-icon {
    font-size: 14px;
}
