.footer {
    background-color: #163664;
    padding: 20px 0;
    .footer-contact {
        padding-top: 20px;
        gap: 30px;
        color: #fff;
        display: flex;
    }
    .footer-divide {
        margin: 10px 0;
        height: 2px;
        width: 100%;
        background-color: #ccc;
    }
    .footer-body {
        // gap: 30px;
    }
    .footer-body-item {
        color: #fff;
        margin-bottom: 30px;
    }
    .footer-body-item-title {
        font-size: 20px;
        font-weight: 500;
        padding-bottom: 15px;
    }
    .footer-body-item-desc {
        font-size: 15px;
        display: block;
        margin-bottom: 5px;
        font-weight: 300;
        &:hover {
            color: #7ecb63;
        }
    }
    .footer-awards-title {
        font-size: 28px;
        font-weight: 500;
        color: #fff;
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
    }
}
.footer-awards {
    display: flex;
    align-items: center;
    justify-content: center;
    .footer-award {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }
    .award-logo {
        scale: 1.2;
    }
}
.footer-copyright {
    font-size: 18px;
    color: #fff;
    letter-spacing: 1px;
    font-weight: 300;
    display: flex;
    justify-content: space-between;

    .footer-copyright-item {
        margin-top: 10px;
    }
}
