.plan-wrap {
    padding-top: 50px;
    // background-color: rgb(249, 249, 249);
    background: linear-gradient(#cddbec, #e5f9e2);
    padding: 40px 0;
}
.plan-title {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 0;
    color: #2f1c6a;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: -1px;
}
.plan-desc {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 0;
    font-weight: 500;
    font-size: 25px;
    line-height: 1.5;
}
.plan-content {
    margin-top: 50px;
    display: flex;
    justify-content: center;

    gap: 40px;
}
/////
.card {
    border-color: #d7d9dd;
    border-radius: 10px;
    transition: 0.3s;
}
.card:hover {
    -webkit-box-shadow: 0 0 5px 5px #ccc;
    -moz-box-shadow: 0 0 5px 5px #ccc;
    box-shadow: 0 0 5px 5px #ccc;
}
.card-body {
    padding: 10px;
}
.card-title {
    display: flex;
    justify-content: center;
    font-size: 35px;
    color: #2f1c6a;
}
.card-subtitle {
    display: flex;
    justify-content: center;
    font-size: 22px;
    color: rgb(93, 162, 68) !important;
    margin-bottom: 30px;
}
.card-feature {
    margin-left: 10px;
    font-size: 20px;
    line-height: 1.8;
}

.card-btn {
    border: none;
    border-radius: 5px;
    background-color: #1a4487;
    width: 50%;
    color: #fff;
    font-size: 22px;
    padding: 6px 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    transition: 0.3s;
}
.card-btn:hover {
    background-color: #5da244;
}
.card-sale,
.card-price {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    align-items: center;
}
.card-sale {
    margin-top: 30px;
    font-size: 18px;
}
.card-price {
    color: #1a4487;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 1.2;
}
.card-more {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-size: 22px;
    color: #5da244;
    cursor: pointer;
}
