.intro-wrap {
    background-color: #c9cfcf;
    display: flex;
}
.intro-img {
    width: 50%;
    height: 100vh;
    object-fit: cover;
}
.intro-space {
    width: 10px;
    height: inherit;
    background-color: #fff;
}
.intro-body {
}
.intro-title {
    margin-top: 15px;
    margin-left: 40px;
    color: #235396;
    font-size: 34px;
    font-weight: 700;
}
.intro-desc {
    margin-top: 15px;
    margin-left: 40px;
    font-size: 24px;
    font-weight: 600;
    color: #163664;
}
.intro-link {
    font-size: 22px;
    margin-top: 15px;
    margin-left: 60px;
    text-decoration: none;
    display: block;
    color: #000;

    font-weight: 500;
    transition: 0.2s;
}
.intro-link:hover {
    color: #5da244;
    font-weight: 600;
}
.intro-sale {
    font-size: 34px;
    font-weight: 600;
    margin-top: 75px;
    margin-left: 75px;
    margin-bottom: 0;
}
.intro-plan {
    margin-top: 20px;
}
.intro-price {
    font-size: 40px;
    font-weight: 700;
    margin-left: 100px;
    color: #235396;
}
.intro-btn {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 100px;
    background-color: #5da244;
    border-radius: 5px;
    border: 2px solid #5da244;
    font-size: 35px;
    font-weight: 600;
    padding: 5px 20px;
    color: #fff;
    display: inline-block;
}
.intro-btn:hover {
    opacity: 0.8;
}
@media screen and (max-width: 769px) {
    .intro-img {
        display: none;
    }
}
