.feature-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.feature-subtitle {
    font-size: 26px;
    padding-top: 30px;
    color: rgb(93, 162, 68);
}
.feature-title {
    font-size: 35px;
    padding: 10px 0 30px;
    font-weight: 700;
    // color: #;
}
.feature-box {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    ///
    border-radius: 5px;
    -webkit-box-shadow: 0 0 5px 3px #ebebeb;
    -moz-box-shadow: 0 0 5px 3px #ccc;
    box-shadow: 0 0 5px 3px #ccc;
    &:hover {
        background-color: #f7f2f2;
    }
}
.feature-row {
    display: flex;
    justify-content: center;
    gap: 30px;
}
.feature-icon {
    margin: 30px 0 10px;
    font-size: 65px;
    color: rgb(93, 162, 68);
    display: flex;
    justify-content: center;
}
.feature-box-title {
    margin-bottom: 0 !important;
    font-size: 18px;
    text-align: center;
    line-height: 1.4;
}
.feature-rate {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: #e7e5e5;
    }
}
.feature-rate-img {
    width: 100%;
    object-fit: cover;
}
.feature-footer {
    width: 100%;
    height: 300px;
    object-fit: cover;
    background-image: url(../../assets/images/room.jpg);
    background-position: -17px -363px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
@media screen and (max-width: 862px) {
    .feature-footer {
        background-position: -505px -363px;
    }
}
@media screen and (max-width: 579px) {
    .feature-footer {
        background-position: -675px -363px;
    }
}
.feature-footer-wrap {
    .feature-footer-title {
        margin-right: 20px;
        font-size: 35px;
        font-weight: 600;
        color: #2f1c6a;
    }
    .feature-footer-body {
        margin-top: 25px;
        display: flex;
        justify-content: center;
        gap: 30px;
        align-items: center;
    }
    .feature-footer-desc {
        font-size: 18px;
    }
    .feature-footer-price {
        margin-top: 10px;
        font-size: 40px;
        font-weight: 600;
        color: #2f1c6a;
    }
    .feature-footer-btn {
        background-color: rgb(93, 162, 68);
        border-radius: 5px;
        border: none;
        font-size: 22px;
        font-weight: 600;
        color: #fff;
        transition: 0.2s;
        &:hover {
            opacity: 0.85;
        }
        cursor: pointer;
    }
    .feature-footer-link {
        padding: 12px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
